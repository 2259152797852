<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal d-flex flex-column align-center">
      <p class="typography-5 fw-600 let-space-12 color-miniheader text-center mb-24">The function is available only for users with a Base subscription</p>
      <button class="cancel-btn" @click="closeModal" id="close"><Icon name="cancel-btn" /></button>
      <button class="primary-btn" id="ok" @click="goToPlan">Buy plan</button>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'BuyPlanModal',
  components: {
    Icon
  },
  emits: ['closeModal'],
  methods: {
    goToPlan () {
      this.$router.push('/paywall');
    },
    closeModal () {
      this.$emit('closeModal');
    }
  }
};
</script>
