<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal" v-if="sendEmailForm && !acceptSharingForm">
      <div class="d-flex justify-between align-center mb-12">
        <p class="typography-5 fw-600 let-space-12 color-miniheader">Share case with your colleague!</p>
        <button class="cancel-btn" @click="closeModal" id="close"><Icon name="cancel-btn" /></button>
      </div>
      <p class="typography-8 mb-24 let-space-04">Even if he is not registered in Gamayun, we will send him an email with an activation link.</p>
      <div class="d-flex flex-column">
        <label for="email" class="typography-caption color-2 mb-4 let-space-08">Colleague Email</label>
        <input
          type="text"
          id="email"
          placeholder="Enter the email address of the person you want to share with"
          class="text-input"
          :class="errors.email && v$.email.$error ? 'error-border' : ''"
          v-model.trim="v$.email.$model"
          @keydown.enter="share"
        >
      </div>
      <div class="error mt-12" v-if="errors.email && v$.email.$error">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.email.email.$invalid">Wrong email address</span>
        </div>
      </div>
      <div class="error mt-12" v-if="serverError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span>{{serverError}}</span>
        </div>
      </div>
      <button class="primary-btn mt-24" id="send-share" :disabled="loading" @click="share">Share</button>
    </div>
    <div class="modal d-flex flex-column align-center" v-if="sharedSuccess">
      <button class="cancel-btn" @click="closeModal" id="close"><Icon name="cancel-btn" /></button>
      <img src="@/assets/icons/finish-mobile-reg.png" alt="" class="mb-24">
      <p class="typography-5 fw-600 let-space-12 color-miniheader mb-24">Case sent successfully</p>
      <button class="primary-btn" id="ok" @click="closeModal">Thank you!</button>
    </div>

    <div class="modal d-flex flex-column align-center" v-if="acceptSharingForm">
      <template v-if="caseSharingOwner">
        <p class="typography-5 fw-600 let-space-12 color-miniheader text-center mb-24">{{caseSharingOwner}} has shared the case with you!</p>
        <p class="typography-8 mb-24 let-space-04 text-center">{{caseSharingOwner}}'s case with all found profiles and connections will now be available in the list of your cases.</p>
      </template>
      <p class="typography-5 fw-600 let-space-12 color-miniheader text-center mb-24" v-else>Error! The case has been deleted or it is impossible to share / accept</p>
      <button class="cancel-btn" @click="acceptSharing" id="close"><Icon name="cancel-btn" /></button>
      <button class="primary-btn" id="ok" @click="acceptSharing">Okay</button>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';
import { email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';

export default {
  name: 'ShareModal',
  components: {
    Icon
  },
  props: ['caseId'],
  data () {
    return {
      email: '',
      sendEmailForm: true,
      sharedSuccess: false,
      needToBuyPlan: false,
      v$: useVuelidate(),
      errors: {
        email: false
      },
      serverError: '',
      loading: false
    };
  },
  validations () {
    return {
      email: {
        email
      }
    };
  },
  computed: {
    ...mapGetters(['acceptSharingForm', 'accountState', 'caseSharingOwner'])
  },
  methods: {
    closeModal () {
      this.$store.commit('setOpenShareModal', false);
    },
    async share () {
      this.serverError = '';
      this.errors.email = this.v$.email.$error;
      if (this.errors.email) return;
      this.loading = true;

      try {
        const payload = {
          method: 'POST',
          url: '/api/user/investigations/sharing',
          body: {
            case_id: this.caseId,
            email: this.email
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.sendEmailForm = false;
        this.sharedSuccess = true;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.serverError = 'Something went wrong, try again later';
        this.loading = false;
      }
    },
    acceptSharing () {
      this.closeModal();
      this.$store.commit('acceptSharingForm', false);
      this.$store.commit('setCaseSharingOwner', null);
      localStorage.removeItem('caseShare');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal img {
  width: 124px;
  height: 124px;
}
</style>
