<template>
  <div class="modal-background"></div>
    <div class="modal-wrap">
      <div class="modal py-48">
        <div v-if="deleteProcess" class="loader-block">
          <img src="@/assets/icons/searching.gif" alt="" class="h-78">
        </div>
        <div v-else>
          <button class="cancel-btn" @click="confirmDelete"><Icon name="cancel-btn" /></button>
          <h2 class="fs-24 fw-600 mb-12 color-text-darker text-center let-space-06">{{ modalHeader }}</h2>
          <p class="fs-18 fw-400 lh-24 mb-24 color-text text-center">{{ modalText }}</p>
          <div class="d-flex jc-center">
            <button class="primary-btn wide-btn fs-13" @click="confirmDelete">{{ modalButtonText }}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'DeleteAllCasesModal',
  components: {
    Icon
  },
  props: {
    modalHeader: {
      default: 'All empty cases will be deleted'
    },
    modalText: {
      default: 'Our service no longer allows you to have cases without searchings'
    },
    modalButtonText: {
      default: 'Delete them'
    },
    cases: {
      default: []
    }
  },
  emits: ['closeModal'],
  data () {
    return {
      deleteProcess: false
    };
  },
  methods: {
    confirmDelete () {
      this.cases.forEach(emptyCase => {
        this.DeleteCase(emptyCase);
      });
      this.$emit('closeModal');
    },
    async DeleteCase (emptyCase) {
      try {
        this.deleteProcess = true;
        const payload = {
          method: 'POST',
          url: '/api/user/invdel',
          body: {
            fid: emptyCase
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteCase', emptyCase);
        this.$store.commit('updateCountOfCases');
        this.deleteProcess = false;
      } catch (error) {
        console.error(error);
        this.deleteProcess = false;
        throw error;
      }
    }
  }
};
</script>
