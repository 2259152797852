<template>
  <h1
    class="typography-1 color-1 mb-24"
    v-if="getAllInvestigationsCount"
  >
    {{ getAllInvestigationsCount }} cases
  </h1>
  <template
    v-for="(investigation, index) in getInvestigations"
    :key="investigation.oid"
  >
    <CaseBlock
      :investigation="investigation"
      :index="index + 1"
      :searchHistory="getSearchHistory[investigation.oid]"
      :connections="relationSearches[investigation.oid]"
      @deleteCase="openDeleteModal"
      @openShareModal="openShareModalWindow"
      @openBuyPlanModal="needToBuyPlan = true"
    />
  </template>
  <div ref="load-checker"></div>
  <div class="d-flex big-loader" v-if="loaderActive">
    <span class="load-circle load-circle--1"></span>
    <span class="load-circle load-circle--2"></span>
    <span class="load-circle load-circle--3"></span>
  </div>
  <AcceptModal v-if="openModal" @closeModal="openModal = false" @confirmAnswer="confirmDelete">
    <p class="typography-5 fw-600 let-space-12 color-1 mb-24 overflow-hidden">Are you sure <br> you want to delete {{caseName}}?</p>
  </AcceptModal>
  <SubscribeModal v-if="openSubscribeModal">
    <p class="typography-5 fw-600 let-space-12 color-1 mb-24">Your Gamayun subscription is invalid</p>
    <p class="mb-24">You need to purchase a license to use these features.</p>
  </SubscribeModal>
  <div class="loader-block" v-if="deleteProcess">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
  <ShareModal :caseId="caseForSharingId" v-if="openShareModal && !openModal" />
  <BuyPlanModal v-if="needToBuyPlan" @closeModal="needToBuyPlan = false" />

  <Feature visible="VUE_APP_NSA_1566">
    <DeleteAllCasesModal v-if="openAllCasesModal" :cases="emptyCases" @closeModal="closeAllCasesModal"/>
  </Feature>
</template>

<script>
import Feature from '@/components/app/Feature';
import CaseBlock from '@/components/CaseBlock';
import AcceptModal from '@/components/app/AcceptModal';
import SubscribeModal from '@/components/app/SubscribeModal';
import ShareModal from '@/components/app/ShareModal';
import BuyPlanModal from '@/components/app/BuyPlanModal';
import DeleteAllCasesModal from '@/components/modals/DeleteAllCasesModal';
import { mapGetters } from 'vuex';
import { eventListener } from '@/utils/helpers';

export default {
  name: 'Cases',
  components: {
    Feature,
    CaseBlock,
    AcceptModal,
    SubscribeModal,
    ShareModal,
    BuyPlanModal,
    DeleteAllCasesModal
  },
  data () {
    return {
      openModal: false,
      currentCase: '',
      caseName: '',
      loaderActive: false,
      deleteProcess: false,
      caseForSharingId: null,
      needToBuyPlan: false,
      emptyCases: [],
      openAllCasesModal: false
    };
  },
  async mounted () {
    this.$store.commit('setSearching', true);
    this.$store.commit('setLoadUrl', true);
    this.$store.commit('clearCases');

    // Get count of all user investigations on server
    const payload = {
      method: 'POST',
      url: '/api/user/investigations/list',
      body: {
        limit: 100,
        offset: 0,
        searches_limit: 100
      }
    };
    const allInvestigations = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
    this.$store.commit('setAllInvestigationsCount', allInvestigations.data.total);
    this.$store.commit('setRelationsSearchesCount', allInvestigations.data.items);
    // Load investigations info with limit
    await this.$store.dispatch('loadInvestigations');

    // Lazy load functionality
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const callback = async (entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        this.loaderActive = true;
        await this.$store.dispatch('loadInvestigations');
        eventListener.emit('changeLimitCasesInSidebar');
        this.loaderActive = false;
      }
    };
    const observer = new IntersectionObserver(callback, options);
    const target = this.$refs['load-checker'];
    observer.observe(target);

    this.$store.commit('setSearching', false);
    this.$store.commit('setLoadUrl', false);

    for (const key in this.relationSearches) {
      if (this.relationSearches[key].searches.total < 1) this.emptyCases.push(key);
    }
    if (this.emptyCases.length > 0) this.openAllCasesModal = true;
  },
  computed: {
    ...mapGetters(['getInvestigations', 'getSearchHistory', 'getAllInvestigationsCount', 'openSubscribeModal', 'openShareModal']),
    relationSearches () {
      const result = {};
      this.$store.state.cases.relationsSearches.forEach(i => {
        result[i.oid] = {
          oid: i.oid,
          relations_searches: i.relations_searches,
          searches: i.searches
        };
      });
      return result;
    }
  },
  methods: {
    openDeleteModal (caseObj) {
      this.currentCase = caseObj.oid;
      this.caseName = caseObj.tag;
      this.openModal = true;
    },
    async confirmDelete () {
      try {
        this.deleteProcess = true;
        this.openModal = false;
        const payload = {
          method: 'POST',
          url: '/api/user/invdel',
          body: {
            fid: this.currentCase
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteCase', this.currentCase);
        this.$store.commit('updateCountOfCases');
        if (this.getInvestigations.length === 0 && this.$route.path === '/') {
          await this.$store.dispatch('createNewCase');
        }
        this.deleteProcess = false;
      } catch (error) {
        console.error(error);
        this.deleteProcess = false;
        throw error;
      }
    },
    openShareModalWindow (caseId) {
      this.$store.commit('setOpenShareModal', true);
      this.caseForSharingId = caseId;
    },
    closeAllCasesModal () {
      this.openAllCasesModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .big-loader {
    justify-content: center;
    .load-circle {
      width: 8px;
      height: 8px;
      margin-right: 8px;
    }
  }
</style>
