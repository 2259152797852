<template>
  <main class="main-layout-wrap">
    <div class="content">
      <Cases />
    </div>
  </main>
</template>

<script>
import Cases from '@/components/Cases';

export default {
  name: 'Main',
  components: {
    Cases
  }
};
</script>
