<template>
  <div class="case mb-16">
    <div class="case__header">
      <template v-if="!editPanel">
        <div class="d-flex align-center">
          <router-link :to="'/case/' + investigation.oid" class="open-case d-flex align-center">
            <div class="case-circle mr-16">{{index}}</div>
            <Tooltip
              :text="investigation.tag"
              :limit="26"
              className="typography-3"
            />
          </router-link>
        </div>
        <div class="d-flex">
          <span class="typography-8 let-space-04 mr-16">{{ profilesSearch }}</span>
          <span class="typography-8 let-space-04 mr-16">{{connectionsCounter}}</span>
          <span class="typography-8 let-space-04 mr-24">{{date(investigation.crtunix)}}</span>
          <button class="color-theme typography-8 mr-16" id="share" @click="share"><Icon name="share" class="mr-8" />Share</button>
          <button class="case-edit-panel mr-12" @click="showEditPanel">
            <Icon name="edit" class="icon-hover" />
          </button>
          <button class="delete-case" @click="openDeleteModal"><Icon name="trash" class="icon-hover" /></button>
        </div>
      </template>
      <div class="d-flex justify-between w-100" v-else>
        <div class="input-block mr-12">
          <input
            type="text"
            placeholder="Enter case name"
            class="case-name text-input pr-185"
            v-model="caseName"
            @keydown.enter="editCase"
            ref="input"
          >
          <span class="input-block__counter">
            <span :class="{ 'color-warning': this.caseName.length > limit }">
              {{ caseName.length }}
            </span>
            /{{ limit }}  would be visible
          </span>
        </div>
        <div class="d-flex">
          <button @click="editPanel = false" class="case-editing-cancel mr-12">
            <Icon name="cancel" />
          </button>
          <button class="case-editing-ok" @click="editCase" v-if="caseName.trim() !== '' && caseName.length >= 1">
            <Icon name="ok" />
          </button>
        </div>
      </div>
    </div>
    <div class="case__list" :style="editPanel ? 'opacity:0.3' : ''" v-if="showHistoryItems && showHistoryItems.length">
      <ul ref="case-list">
        <li v-for="history in showHistoryItems" :key="history.rid" @click="setSelectedEntity">
          <router-link :to="'/case/' + investigation.oid + '?search=' + history.rid" class="open-search">
            <div>
              <span v-if="!checkQueryFields(history.query)">Id: {{history.rid}}</span>
              <template v-for="(query, name) in history.query" :key="name">
                <span v-if="query">{{name[0].toUpperCase() + name.replace('_', ' ').substring(1)}}: {{getReadableSearchQuery(name, query)}} &nbsp;</span>
              </template>
            </div>
            <span class="case__search-date">{{date(history.crtunix)}}</span>
          </router-link>
        </li>
        <li v-for="(history, index) in showRelationsItems" :key="index">
          <a class="open-search">
            <span class="pointer w-100" @click="openConnection(history)">{{replacer(history.title)}} connection &nbsp;</span>
            <span class="case__search-date">{{date(history.created_at)}}</span>
          </a>
        </li>
        <li class="d-flex justify-between" v-if="showButtonHide || showButtonMore">
          <a class="case-searches-hide-btn color-theme fw-600 more-link" @click="hideHistory" v-if="showButtonHide">
            Hide
          </a>
          <a class="case-searches-more-btn color-theme fw-600 more-link" @click="showAllHistory" v-if="showButtonMore">
            More
          </a>
        </li>
      </ul>
    </div>
    <!-- <div class="case__footer" :style="editPanel ? 'opacity:0.3' : ''">
      <div class="d-flex align-center">
        <button class="typography-theme color-theme mr-24">New profile search</button>
        <button class="typography-theme color-theme">New connection search</button>
      </div>
      <div class="d-flex align-center justify-end">
        <button class="typography-theme color-theme mr-48">Saved in tags</button>
        <button class="primary-btn typography-base">Report</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import Icon from './app/Icon';
import { getReadableSearchQuery, dateFormat } from '../utils/helpers';
import { mapGetters } from 'vuex';
import Tooltip from '@/components/UI/Tooltip';

export default {
  name: 'Case',
  components: {
    Icon,
    Tooltip
  },
  emits: ['deleteCase', 'openShareModal', 'openBuyPlanModal'],
  props: ['investigation', 'index', 'searchHistory', 'connections'],
  data () {
    return {
      editPanel: false,
      caseName: '',
      limitToSHow: 3,
      limitToShowConnections: 2,
      limit: 25,
      showTooltip: false
    };
  },
  computed: {
    ...mapGetters(['accountState']),
    profilesSearch () {
      if (this.searchHistory && this.searchHistory.length > 1) {
        return this.searchHistory.length + ' searches';
      } else if (this.searchHistory && this.searchHistory.length === 1) {
        return this.searchHistory.length + ' search';
      } else {
        return '';
      }
    },
    connectionsSearch () {
      return this.connections ? this.connections : '';
    },
    connectionsCounter () {
      if (this.connections?.relations_searches?.total > 1) {
        return this.connections.relations_searches.total + ' connections';
      } else if (this.connections?.relations_searches?.total === 1) {
        return this.connections.relations_searches.total + ' connection';
      } else {
        return '';
      }
    },
    showHistoryItems () {
      if (this.searchHistory && this.searchHistory.length) {
        return this.searchHistory.slice(0, this.limitToSHow);
      } else return [];
    },
    showRelationsItems () {
      if (this.connections?.relations_searches.items && this.connections.relations_searches.items.length) {
        return this.connections.relations_searches.items.slice(0, this.limitToShowConnections);
      }
      return false;
    },
    showButtonMore () {
      const historyLength = this.searchHistory?.length || 0;
      const relationItemsLength = this.connections?.relations_searches?.items?.length || 0;
      return !(historyLength <= this.limitToSHow) || !((relationItemsLength <= this.limitToShowConnections));
    },
    showButtonHide () {
      if ((this.showHistoryItems && this.showHistoryItems.length > 3) || (this.showRelationsItems && this.showRelationsItems.length > 2)) {
        return true;
      }
      return '';
    }
  },
  methods: {
    replacer (str) {
      if (str.includes('{end}')) return str.replace('{end}', "'s");
      if (str.includes('{middle}')) return str.replace('{middle}', ' & ');
      return '';
    },
    setSelectedEntity () {
      this.$store.commit('selectEntity', 'search');
    },
    async openConnection (search) {
      this.$store.commit('selectEntity', 'connection');
      await this.$router.push({ path: '/case/' + this.investigation.oid });
      this.$store.commit('activateRelationsSection', true);
      await this.$store.dispatch('openConnections', search);
      this.$nextTick(async () => { await this.$store.dispatch('stopSearching', true); });
      document.getElementsByClassName('content')[0].scrollIntoView();
      this.$store.commit('selectEntity', '');
    },
    getReadableSearchQuery (queryName, query) {
      return getReadableSearchQuery(queryName, query);
    },
    showEditPanel () {
      this.editPanel = true;
      this.caseName = this.investigation.tag;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    async editCase () {
      if (this.caseName.trim() === '' || this.caseName.length < 1) return;
      try {
        const caseInfo = {
          fid: this.investigation.oid,
          tag: this.caseName
        };
        await this.$store.dispatch('editCase', caseInfo);
        this.$store.commit('editCase', { id: this.investigation.oid, name: this.caseName });
        this.editPanel = false;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    openDeleteModal () {
      this.$emit('deleteCase', this.investigation);
    },
    date (val) {
      return dateFormat(val);
    },
    checkQueryFields (history) {
      let i = 0;
      for (const key in history) {
        if (history[key]) i++;
      }
      return !!i;
    },
    showAllHistory () {
      this.limitToSHow = this.searchHistory.length;
      if (this.connections?.relations_searches?.items && this.connections.relations_searches.items.length) {
        this.limitToShowConnections = this.connections.relations_searches.items.length;
      } else {
        this.limitToShowConnections = 0;
      }
    },
    hideHistory () {
      this.limitToSHow = 3;
      this.limitToShowConnections = 2;
    },
    share () {
      if (this.accountState.subscription.code !== 'base_month' && this.accountState.subscription.code !== 'base_year') {
        this.$emit('openBuyPlanModal');
        return;
      }
      this.$emit('openShareModal', this.investigation.oid);
    }
  }
};
</script>
